import React from 'react'
import { graphql } from 'gatsby'
import Helmet from "react-helmet"
import "../styles/global.css"
import Navbar from './Navbar'
import {FaInstagram,FaFacebookF,FaWhatsapp} from 'react-icons/fa';



export default function productDetails({data}) {
    const {title, images, details, productId, pic} = data.markdownRemark.frontmatter ;
    return (
                      <div  style={{backgroundColor:"#FAFAFA", position:"relative", height:"100%"}}   >
                        <Helmet>
                            <html lang="fr" />
                            <title>Pharmacie La Roche Votre Santé, Notre Passion </title>
                            <meta name="description" content="Pharmacie la Roche est plus qu'une pharmacie, c'est un espace qui vous offre les meilleurs services et la commodité de choisir parmi les meilleurs de nos produits, en mettant à votre disposition une équipe qualifiée, chaleureuse et dynamique pour vous écouter et vous fournir les meilleurs conseils ainsi pour une analyse précise des ordonnances avant dispensation des médicaments."/>
                            <meta name="viewport" content="width=device-width, initial-scale=1"/>
                            <script src="https://cdn.jsdelivr.net/npm/typed.js@2.0.12"/>
                            <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.0/jquery.min.js"/>
                            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"/>
                            <script src=" https://code.jquery.com/jquery-2.2.4.min.js"/>
                       
                        </Helmet> 
                        <Navbar logo="../../logo.png" color="black" />  
                        <div style={{ backgroundColor:"#FAFAFA", height:"auto", minHeight:"100vh"}} key={productId} className="products-page">
                        
                            <div  className="section-title">
                                <h1>{title}</h1>
                                <hr/>
                            </div>
                            {
                                pic || details ? 
                           
                            <div className="flex" style={{justifyContent:"center"}}>
                            {
                                pic  
                                ? <div className="img">
                                      <img alt="produit-img" style={{width:"80%", height:"auto", justifySelf:"center",   marginBottom:"20px", borderRadius:"16px"}} src={pic}/>
                                  </div>
                                : <></>
                            }
                           
                            <div className="img"  style={{ display:"flex", flexDirection:"row", textAlign:"center", alignContent:"center", justifyContent:"center",marginTop:"40px", paddingBottom:"40px"}}>   
                            <p>
                            {
                            details &&  details.map((detail,key) =>(         
                                        <span key={key}> {detail} </span> ))
                            }  
                            </p>   
                            </div>
                            </div>
                            : <></>
                             }
                                
                            <div className="brand-grid" style={{paddingBottom:"20rem"}}>
                                {
                                  images && images.map(img =>(
                                        
                                        <div className="brand-item">
                                            <img style={{width:'100%', height:"80%"}} alt="marque" src={img}/>
                                        </div>
                                    )
                                    )
                                }
                                
                            </div>
                       
                         
                            
                        </div>
                        
                        <footer style={{backgroundColor:"#98AFBA",position:"absolute", bottom:"0px", width:"100%", zIndex:"9"}}>
                                    
                                    <div style={{justifyContent:"space-around", padding:"20px", position:"relative"}} className="reverse">
                                        <div id="footer-info" style={{paddingBottom:"2rem"}}>
                                            <h3 style={{fontSize:"16px", color:"white", fontWeight:700}} >Pharmacie La Roche.</h3>

                                            <h3 style={{fontSize:"16px", color:"white", fontWeight:700}}>Droits d'auteur @2021 Tous droits reservés</h3>
                                            
                                        </div>
                                        
                                        <div id="footer-links" >
                                            <ul>
                                                <li><a href="#home">Accueil</a></li>
                                                <li><a href="#about">Qui sommes-nous ?</a></li>
                                                <li><a href="#products">Produits</a></li>
                                                <li><a href="#services">Services</a></li>
                                                <li><a href="#contact">Contact</a></li>

                                            </ul>
                                        </div>
                                        <div style={{alignSelf:"center"}}>
                                            <ul className="footer-icons">
                                                <li>
                                                    <a className="link" target="_blank"  href="https://www.facebook.com/pharmacielaroche" rel="noreferrer">
                                                        <FaFacebookF className="social-icon"/>          
                                                    </a> 
                                                </li>
                                                <li>
                                                    <a className="link" target="_blank"  href="https://www.instagram.com/pharmacie.laroche/" rel="noreferrer">
                                                        <FaInstagram className="social-icon"/>          
                                                    </a> 
                                                </li>
                                                
                                                <li>
                                                    <a className="link" target="_blank"  href="https://api.whatsapp.com/send/?phone=213561890360&text&app_absent=0" rel="noreferrer">
                                                        <FaWhatsapp className="social-icon"/>          
                                                    </a> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                            </footer>
                                            

                </div>
                        
            

    )
}

export const query = graphql`
  query ProductsPage($productId: Int)  {
     markdownRemark(frontmatter: {productId: {eq: $productId}}) {
        frontmatter {
          details
          images
          title
          pic
          productId
        }
      }
  }
  `